// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IEmissionStore } from './models';

const initialState: any = {
  steps: {
    loading: false,
    error: false,
    message: '',
    data: {}
  },
  bank: {
    loading: false,
    error: false,
    message: '',
    data: []
  },
  card: {
    loading: false,
    error: false,
    message: '',
    data: []
  },
  nationality: {
    loading: false,
    error: false,
    message: '',
    data: []
  },
  province: {
    loading: false,
    error: false,
    message: '',
    data: []
  },
  contractor_city: {
    loading: false,
    error: false,
    message: '',
    data: []
  },
  contractor_neighborhood: {
    loading: false,
    error: false,
    message: '',
    data: []
  },
  insured_city: {
    loading: false,
    error: false,
    message: '',
    data: []
  },
  insured_neighborhood: {
    loading: false,
    error: false,
    message: '',
    data: []
  },
  //persona juridica
  contractor_city_legal: {
    loading: false,
    error: false,
    message: '',
    data: []
  },
  contractor_neighborhood_legal: {
    loading: false,
    error: false,
    message: '',
    data: []
  },
  creditor_city: {
    loading: false,
    error: false,
    message: '',
    data: []
  },
  creditor_neighborhood: {
    loading: false,
    error: false,
    message: '',
    data: []
  },
  idType: {
    loading: false,
    error: false,
    message: '',
    data: []
  },
  maritalStatus: {
    loading: false,
    error: false,
    message: '',
    data: []
  },
  activity: {
    loading: false,
    error: false,
    message: '',
    data: []
  },
  issue: {
    loading: false,
    error: false,
    message: '',
    data: {}
  },
  listSources: {
    loading: false,
    error: false,
    data: {}
  },
  initializePersistence: {
    loading: false,
    error: false,
    data: 0
  },
  saveFirstStep: {
    loading: false,
    error: false,
    data: 0
  },
  saveSecondStep: {
    loading: false,
    error: false,
    data: 0
  },
  // saveThirdStep: {
  //   loading: false,
  //   error: false,
  //   data: 0
  // },
  saveFourthStep: {
    loading: false,
    error: false,
    data: 0
  },
  quotationById: {
    loading: false,
    error: false,
    data: {}
  },
  secondInstallmentDate: {
    loading: false,
    error: false,
    data: {}
  }
};

export const emissionStepsReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.ISSUE.STEPS.FETCH:
      return {
        ...state,
        steps: {
          ...state.steps,
          loading: true,
          error: false
        }
      };
    case ACTIONS.ISSUE.STEPS.SUCCESS:
      return {
        ...state,
        steps: {
          ...state.steps,
          loading: false,
          error: false,
          data: {
            ...state.steps.data,
            ...action.payload.data.input_flow.filter((el: any) => el.flow === 'ISSUE')[0]
          }
          //si trae solo el objeto correspondiente a issue, usar lo siguiente:
          //data: { ...state.steps.data, ...action.payload.data.input_flow[0].input_groups[0] }
        }
      };
    case ACTIONS.ISSUE.STEPS.FAIL:
      return {
        ...state,
        steps: {
          ...state.steps,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.ISSUE.BANK.FETCH:
      return {
        ...state,
        bank: {
          ...state.bank,
          loading: true,
          error: false
        }
      };
    case ACTIONS.ISSUE.BANK.SUCCESS:
      return {
        ...state,
        bank: {
          ...state.bank,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.ISSUE.BANK.FAIL:
      return {
        ...state,
        bank: {
          ...state.bank,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.ISSUE.CARD.FETCH:
      return {
        ...state,
        card: {
          ...state.card,
          loading: true,
          error: false
        }
      };
    case ACTIONS.ISSUE.CARD.SUCCESS:
      return {
        ...state,
        card: {
          ...state.card,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.ISSUE.CARD.FAIL:
      return {
        ...state,
        card: {
          ...state.card,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.ISSUE.NATIONALITY.FETCH:
      return {
        ...state,
        nationality: {
          ...state.nationality,
          loading: true,
          error: false
        }
      };
    case ACTIONS.ISSUE.NATIONALITY.SUCCESS:
      return {
        ...state,
        nationality: {
          ...state.nationality,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.ISSUE.NATIONALITY.FAIL:
      return {
        ...state,
        nationality: {
          ...state.nationality,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.ISSUE.PROVINCE.FETCH:
      return {
        ...state,
        province: {
          ...state.province,
          loading: false,
          error: false
        }
      };
    case ACTIONS.ISSUE.PROVINCE.SUCCESS:
      return {
        ...state,
        province: {
          ...state.province,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.ISSUE.PROVINCE.FAIL:
      return {
        ...state,
        province: {
          ...state.province,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.ISSUE.CITY.FETCH:
      return {
        ...state,
        [action.payload.params.path]: {
          ...state[action.payload.params.path],
          loading: false,
          error: false
        }
      };
    case ACTIONS.ISSUE.CITY.SUCCESS:
      return {
        ...state,
        [action.payload.path]: {
          ...state[action.payload.path],
          loading: false,
          error: false,
          data: action.payload.data
        }
      };
    case ACTIONS.ISSUE.CITY.FAIL:
      return {
        ...state,
        [action.payload.path]: {
          ...state[action.payload.path],
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.ISSUE.NEIGHBORHOOD.FETCH:
      return {
        ...state,
        [action.payload.params.path]: {
          ...state[action.payload.params.path],
          loading: false,
          error: false
        }
      };
    case ACTIONS.ISSUE.NEIGHBORHOOD.SUCCESS:
      return {
        ...state,
        [action.payload.path]: {
          ...state[action.payload.path],
          loading: false,
          error: false,
          data: action.payload.data
        }
      };
    case ACTIONS.ISSUE.NEIGHBORHOOD.FAIL:
      return {
        ...state,
        [action.payload.path]: {
          ...state[action.payload.path],
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.ISSUE.ID_TYPE.FETCH:
      return {
        ...state,
        idType: {
          ...state.idType,
          loading: true,
          error: false
        }
      };
    case ACTIONS.ISSUE.ID_TYPE.SUCCESS:
      return {
        ...state,
        idType: {
          ...state.idType,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.ISSUE.ID_TYPE.FAIL:
      return {
        ...state,
        idType: {
          ...state.idType,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.ISSUE.MARITAL_STATUS.FETCH:
      return {
        ...state,
        maritalStatus: {
          ...state.maritalStatus,
          loading: true,
          error: false
        }
      };
    case ACTIONS.ISSUE.MARITAL_STATUS.SUCCESS:
      return {
        ...state,
        maritalStatus: {
          ...state.maritalStatus,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.ISSUE.MARITAL_STATUS.FAIL:
      return {
        ...state,
        maritalStatus: {
          ...state.maritalStatus,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.ISSUE.ACTIVITY.FETCH:
      return {
        ...state,
        activity: {
          ...state.activity,
          loading: true,
          error: false
        }
      };
    case ACTIONS.ISSUE.ACTIVITY.SUCCESS:
      return {
        ...state,
        activity: {
          ...state.activity,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.ISSUE.ACTIVITY.FAIL:
      return {
        ...state,
        activity: {
          ...state.activity,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.ISSUE.CREATE.FETCH:
      return {
        ...state,
        issue: {
          ...state.issue,
          loading: true,
          error: false
        }
      };
    case ACTIONS.ISSUE.CREATE.SUCCESS:
      return {
        ...state,
        issue: {
          ...state.issue,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.ISSUE.CREATE.FAIL:
      return {
        ...state,
        issue: {
          ...state.issue,
          loading: false,
          error: true,
          message: action.payload.error,
          timeout: action.payload.error.timeout ?? false
        }
      };
    case ACTIONS.ISSUE.CLEAR_ERROR:
      return {
        ...state,
        saveFirstStep: {
          loading: false,
          error: false,
          message: '',
          data: 0
        },
        saveSecondStep: {
          loading: false,
          error: false,
          message: '',
          data: 0
        },
        saveFourthStep: {
          loading: false,
          error: false,
          message: '',
          data: 0
        },
        issue: { loading: false, error: false, message: '', data: {} }
      };
    case ACTIONS.ISSUE.CLEAN:
      return { ...initialState };
    case ACTIONS.ISSUE.LISTSOURCES.FETCH:
      return {
        ...state,
        listSources: {
          ...state.listSources,
          loading: true,
          error: false
        }
      };
    case ACTIONS.ISSUE.LISTSOURCES.SUCCESS:
      return {
        ...state,
        listSources: {
          ...state.listSources,
          loading: false,
          error: false,
          data: { ...state.listSources.data, ...action.payload }
        }
      };
    case ACTIONS.ISSUE.LISTSOURCES.FAIL:
      return {
        ...state,
        listSources: {
          ...state.listSources,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.ISSUE.INITIALIZE_PERSISTENCE.FETCH:
      return {
        ...state,
        initializePersistence: {
          ...state.initializePersistence,
          loading: true,
          error: false
        }
      };
    case ACTIONS.ISSUE.INITIALIZE_PERSISTENCE.SUCCESS:
      return {
        ...state,
        initializePersistence: {
          ...state.initializePersistence,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.ISSUE.INITIALIZE_PERSISTENCE.FAIL:
      return {
        ...state,
        initializePersistence: {
          ...state.initializePersistence,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.ISSUE.SAVE_FIRST_STEP.FETCH:
      return {
        ...state,
        saveFirstStep: {
          ...state.saveFirstStep,
          loading: true,
          error: false
        }
      };
    case ACTIONS.ISSUE.SAVE_FIRST_STEP.SUCCESS:
      return {
        ...state,
        saveFirstStep: {
          ...state.saveFirstStep,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.ISSUE.SAVE_FIRST_STEP.FAIL:
      return {
        ...state,
        saveFirstStep: {
          ...state.saveFirstStep,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.ISSUE.SAVE_SECOND_STEP.FETCH:
      return {
        ...state,
        saveSecondStep: {
          ...state.saveSecondStep,
          loading: true,
          error: false
        }
      };
    case ACTIONS.ISSUE.SAVE_SECOND_STEP.SUCCESS:
      return {
        ...state,
        saveSecondStep: {
          ...state.saveSecondStep,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.ISSUE.SAVE_SECOND_STEP.FAIL:
      return {
        ...state,
        saveSecondStep: {
          ...state.saveSecondStep,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    // case ACTIONS.ISSUE.SAVE_THIRD_STEP.FETCH:
    //   return {
    //     ...state,
    //     saveThirdStep: {
    //       ...state.saveThirdStep,
    //       loading: true,
    //       error: false
    //     }
    //   };
    // case ACTIONS.ISSUE.SAVE_THIRD_STEP.SUCCESS:
    //   return {
    //     ...state,
    //     saveThirdStep: {
    //       ...state.saveThirdStep,
    //       loading: false,
    //       error: false,
    //       data: action.payload
    //     }
    //   };
    // case ACTIONS.ISSUE.SAVE_THIRD_STEP.FAIL:
    //   return {
    //     ...state,
    //     saveThirdStep: {
    //       ...state.saveThirdStep,
    //       loading: false,
    //       error: true,
    //       message: action.payload.error
    //     }
    //   };
    case ACTIONS.ISSUE.SAVE_FOURTH_STEP.FETCH:
      return {
        ...state,
        saveFourthStep: {
          ...state.saveFourthStep,
          loading: true,
          error: false
        }
      };
    case ACTIONS.ISSUE.SAVE_FOURTH_STEP.SUCCESS:
      return {
        ...state,
        saveFourthStep: {
          ...state.saveFourthStep,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.ISSUE.SAVE_FOURTH_STEP.FAIL:
      return {
        ...state,
        saveFourthStep: {
          ...state.saveFourthStep,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.QUOTATION_BY_ID.FETCH:
      return {
        ...state,
        quotationById: {
          ...state.quotationById,
          loading: true,
          error: false
        }
      };
    case ACTIONS.QUOTATION_BY_ID.SUCCESS:
      return {
        ...state,
        quotationById: {
          ...state.quotationById,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.QUOTATION_BY_ID.FAIL:
      return {
        ...state,
        quotationById: {
          ...state.quotationById,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.QUOTATION_BY_ID.CLEAN:
      return {
        ...state,
        steps: {
          loading: false,
          error: false,
          message: '',
          data: {}
        }
      };
    case ACTIONS.ISSUE.SECOND_INSTALLMENT_DATE.FETCH:
      return {
        ...state,
        secondInstallmentDate: {
          ...state.secondInstallmentDate,
          loading: true,
          error: false
        }
      };
    case ACTIONS.ISSUE.SECOND_INSTALLMENT_DATE.SUCCESS:
      return {
        ...state,
        secondInstallmentDate: {
          ...state.secondInstallmentDate,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.ISSUE.SECOND_INSTALLMENT_DATE.FAIL:
      return {
        ...state,
        secondInstallmentDate: {
          ...state.secondInstallmentDate,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    default:
      return state;
  }
};
