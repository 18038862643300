import { call, put, takeLatest, takeEvery, all } from 'redux-saga/effects';
import Api from './Api';
import ApiMock from './ApiMock';
import {
  getStepsSuccess,
  getStepsFail,
  getListSourceSuccess,
  getListSourceFail,
  getDataSourceSuccess,
  getDataSourceFail,
  getContactsSuccess,
  getContactsFail,
  createContactSuccess,
  createContactFail,
  getPlansSuccess,
  getPlansFail,
  getCommercialPlanSuccess,
  getCommercialPlanFail,
  getBillingModeSuccess,
  getBillingModeFail,
  getPaymentMethodSuccess,
  getPaymentMethodFail,
  getPaymentConditionSuccess,
  getPaymentConditionFail,
  updateQuotationSuccess,
  updateQuotationFail,
  getProducerSuccess,
  getProducerFail,
  getAccessorySuccess,
  getAccessoryFail
} from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../../ActionTypes';

interface IUsersData {
  data?: any;
}

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? ApiMock() : Api();

// eslint-disable-next-line no-unused-vars
function* sagasGetStepsFetch(action: actionType) {
  try {
    const response: IUsersData = yield call(api.getStepsFetch, action.payload.params);
    const { data } = response;
    yield put(getStepsSuccess(data));
  } catch (e) {
    yield put(getStepsFail(e));
  }
}

function* sagasGetListSourceFetch(action: actionType) {
  try {
    const response: IUsersData = yield call(api.getSourceList, action.payload.params);
    const { data } = response;

    yield put(getListSourceSuccess({ [action.payload.params.field]: data }));
  } catch (e) {
    yield put(getListSourceFail(e));
  }
}

function* sagasGetDataSourceFetch(action: actionType) {
  try {
    const response: IUsersData = yield call(api.getSourceData, action.payload.params);
    const { data } = response;

    yield put(getDataSourceSuccess({ [action.payload.params.field]: data }));
  } catch (e) {
    yield put(getDataSourceFail(e));
  }
}

function* sagasGetContactsByPartialFetch(action: actionType) {
  try {
    const response: IUsersData = yield call(api.getContactsByPartial, action.payload.params);
    const { data } = response;
    yield put(getContactsSuccess(data));
  } catch (e) {
    yield put(getContactsFail(e));
  }
}

function* sagasCreateContactFetch(action: actionType) {
  try {
    const response: any = yield call(api.createContact, action.payload.params);
    const { data } = response;
    yield put(createContactSuccess(data));
  } catch (e) {
    yield put(createContactFail(e.response.data));
  }
}

function* sagasGetPlansFetch(action: actionType) {
  try {
    const response: any = yield call(api.getPlansFetch, action.payload.params);
    const { data } = response;
    yield put(getPlansSuccess(data));
  } catch (e) {
    yield put(getPlansFail(e.response.data));
  }
}

function* sagasGetCommercialPlanFetch(action: actionType) {
  try {
    const response: any = yield call(api.getCommercialPlanFetch, action.payload.params);
    const { data } = response;
    yield put(getCommercialPlanSuccess(data));
  } catch (e) {
    yield put(getCommercialPlanFail(e.response.data));
  }
}

function* sagasGetBillingMethodFetch(action: actionType) {
  try {
    const response: any = yield call(api.getBillingModeFetch, action.payload.params);
    const { data } = response;
    yield put(getBillingModeSuccess(data));
  } catch (e) {
    yield put(getBillingModeFail(e.response.data));
  }
}

function* sagasGetPaymentMethodFetch(action: actionType) {
  try {
    const response: any = yield call(api.getPaymentMethodFetch, action.payload.params);
    const { data } = response;
    yield put(getPaymentMethodSuccess(data));
  } catch (e) {
    yield put(getPaymentMethodFail(e.response.data));
  }
}

function* sagasGetPaymentConditionFetch(action: actionType) {
  try {
    const response: any = yield call(api.getPaymentConditionFetch, action.payload.params);
    const { data } = response;
    yield put(getPaymentConditionSuccess(data));
  } catch (e) {
    yield put(getPaymentConditionFail(e.response.data));
  }
}

function* sagasGetProducerFetch() {
  try {
    const response: any = yield call(api.getProducerFetch);
    const { data } = response;
    yield put(getProducerSuccess(data));
  } catch (e) {
    yield put(getProducerFail(e.response.data));
  }
}

function* sagasUpdateQuotationFetch(action: actionType) {
  try {
    yield call(api.updatePlansFetch, action.payload.quotationId, action.payload.params);
    yield put(updateQuotationSuccess({ response: 'OK' }));
  } catch (e) {
    yield put(updateQuotationFail(e?.response?.data));
  }
}

function* sagasGetAccessoryFetch(action: actionType) {
  try {
    const response: any = yield call(api.getAccessoryFetch, action.payload.params);
    const { data } = response;
    yield put(getAccessorySuccess(data));
  } catch (e) {
    yield put(getAccessoryFail(e.response.data));
  }
}

// Listen for redux actions
function* quotationsSaga() {
  yield all([takeLatest(ACTIONS.QUOTATIONS.STEPS.FETCH, sagasGetStepsFetch)]);
  yield all([takeEvery(ACTIONS.QUOTATIONS.LISTSOURCES.FETCH, sagasGetListSourceFetch)]);
  yield all([takeEvery(ACTIONS.QUOTATIONS.DATASOURCES.FETCH, sagasGetDataSourceFetch)]);
  yield all([takeLatest(ACTIONS.QUOTATIONS.CONTACTS.FETCH, sagasGetContactsByPartialFetch)]);
  yield all([takeLatest(ACTIONS.QUOTATIONS.NEW_CONTACT.FETCH, sagasCreateContactFetch)]);
  yield all([takeLatest(ACTIONS.QUOTATIONS.PLANS.FETCH, sagasGetPlansFetch)]);
  yield all([takeLatest(ACTIONS.QUOTATIONS.COMMERCIAL_PLAN.FETCH, sagasGetCommercialPlanFetch)]);
  yield all([takeLatest(ACTIONS.QUOTATIONS.BILLING_MODE.FETCH, sagasGetBillingMethodFetch)]);
  yield all([takeLatest(ACTIONS.QUOTATIONS.PAYMENT_METHOD.FETCH, sagasGetPaymentMethodFetch)]);
  yield all([takeLatest(ACTIONS.QUOTATIONS.PAYMENT_CONDITION.FETCH, sagasGetPaymentConditionFetch)]);
  yield all([takeLatest(ACTIONS.QUOTATIONS.PRODUCER.FETCH, sagasGetProducerFetch)]);
  yield all([takeLatest(ACTIONS.QUOTATIONS.UPDATE.FETCH, sagasUpdateQuotationFetch)]);
  yield all([takeLatest(ACTIONS.QUOTATIONS.ACCESORY.FETCH, sagasGetAccessoryFetch)]);
}

export { quotationsSaga };
