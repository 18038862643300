// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IBaseStore } from './models';

const initialState: IBaseStore = {
  steps: {
    loading: false,
    error: false,
    message: '',
    data: {}
  },
  listSources: {
    loading: false,
    error: false,
    data: {}
  },
  dataSources: {
    loading: false,
    error: false,
    data: {}
  },
  contacts: {
    loading: false,
    error: false,
    data: []
  },
  newContact: {
    loading: false,
    error: false,
    data: {}
  },
  plans: {
    loading: false,
    error: false,
    data: {}
  },
  commercialPlan: {
    loading: false,
    error: false,
    data: []
  },
  billingMode: {
    loading: false,
    error: false,
    data: []
  },
  paymentMethod: {
    loading: false,
    error: false,
    data: []
  },
  paymentCondition: {
    loading: false,
    error: false,
    data: []
  },
  update: {
    loading: false,
    error: false,
    data: {}
  },
  producer: {
    loading: false,
    error: false,
    data: []
  },
  accesory: {
    loading: false,
    error: false,
    data: []
  }
};

export const quotationStepsReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    case ACTIONS.QUOTATIONS.STEPS.FETCH:
      return {
        ...state,
        steps: {
          ...state.steps,
          loading: true,
          error: false
        }
      };
    case ACTIONS.QUOTATIONS.STEPS.SUCCESS:
      return {
        ...state,
        steps: {
          ...state.steps,
          loading: false,
          error: false,
          data: { ...state.steps.data, ...action.payload.data.input_flow[0] }
        }
      };
    case ACTIONS.QUOTATIONS.STEPS.FAIL:
      return {
        ...state,
        steps: {
          ...state.steps,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.QUOTATIONS.LISTSOURCES.FETCH:
      return {
        ...state,
        listSources: {
          ...state.listSources,
          loading: true,
          error: false
        }
      };
    case ACTIONS.QUOTATIONS.LISTSOURCES.SUCCESS:
      return {
        ...state,
        listSources: {
          ...state.listSources,
          loading: false,
          error: false,
          data: { ...state.listSources.data, ...action.payload }
        }
      };
    case ACTIONS.QUOTATIONS.LISTSOURCES.FAIL:
      return {
        ...state,
        listSources: {
          ...state.listSources,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.QUOTATIONS.DATASOURCES.FETCH:
      return {
        ...state,
        dataSources: {
          ...state.dataSources,
          loading: true,
          error: false
        }
      };
    case ACTIONS.QUOTATIONS.DATASOURCES.SUCCESS:
      return {
        ...state,
        dataSources: {
          ...state.listSources,
          loading: false,
          error: false,
          data: { ...state.dataSources.data, ...action.payload }
        }
      };
    case ACTIONS.QUOTATIONS.DATASOURCES.FAIL:
      return {
        ...state,
        dataSources: {
          ...state.dataSources,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.QUOTATIONS.CONTACTS.FETCH:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: true,
          error: false
        }
      };
    case ACTIONS.QUOTATIONS.CONTACTS.SUCCESS:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: false,
          error: false,
          data: action.payload.data
        }
      };
    case ACTIONS.QUOTATIONS.CONTACTS.FAIL:
      return {
        ...state,
        contacts: {
          ...state.contacts,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.QUOTATIONS.LISTSOURCES.SET_ITEMS_FROM_INPUTTER:
      return {
        ...state,
        listSources: {
          ...state.listSources,
          loading: false,
          error: false,
          data: { ...state.listSources.data, ...action.payload }
        }
      };
    case ACTIONS.QUOTATIONS.CONTACTS.CLEAR:
      return {
        ...state,
        newContact: {
          ...state.newContact,
          loading: false,
          error: false,
          data: []
        }
      };
    case ACTIONS.QUOTATIONS.NEW_CONTACT.FETCH:
      return {
        ...state,
        newContact: {
          ...state.newContact,
          loading: false,
          error: false
        }
      };
    case ACTIONS.QUOTATIONS.NEW_CONTACT.SUCCESS:
      return {
        ...state,
        newContact: {
          ...state.newContact,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.QUOTATIONS.NEW_CONTACT.FAIL:
      return {
        ...state,
        newContact: {
          ...state.newContact,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.QUOTATIONS.PLANS.FETCH:
      return {
        ...state,
        plans: {
          ...state.plans,
          loading: true,
          error: false
        }
      };
    case ACTIONS.QUOTATIONS.PLANS.SUCCESS:
      return {
        ...state,
        plans: {
          ...state.plans,
          loading: false,
          error: false,
          data: action.payload.data
        }
      };
    case ACTIONS.QUOTATIONS.PLANS.FAIL:
      return {
        ...state,
        plans: {
          ...state.plans,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.QUOTATIONS.COMMERCIAL_PLAN.FETCH:
      return {
        ...state,
        commercialPlan: {
          ...state.commercialPlan,
          loading: true,
          error: false
        }
      };
    case ACTIONS.QUOTATIONS.COMMERCIAL_PLAN.SUCCESS:
      return {
        ...state,
        commercialPlan: {
          ...state.commercialPlan,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.QUOTATIONS.COMMERCIAL_PLAN.FAIL:
      return {
        ...state,
        commercialPlan: {
          ...state.commercialPlan,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.QUOTATIONS.BILLING_MODE.FETCH:
      return {
        ...state,
        billingMode: {
          ...state.billingMode,
          loading: true,
          error: false
        }
      };
    case ACTIONS.QUOTATIONS.BILLING_MODE.SUCCESS:
      return {
        ...state,
        billingMode: {
          ...state.billingMode,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.QUOTATIONS.BILLING_MODE.FAIL:
      return {
        ...state,
        billingMode: {
          ...state.billingMode,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.QUOTATIONS.PAYMENT_METHOD.FETCH:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          loading: true,
          error: false
        }
      };
    case ACTIONS.QUOTATIONS.PAYMENT_METHOD.SUCCESS:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.QUOTATIONS.PAYMENT_METHOD.FAIL:
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.QUOTATIONS.PAYMENT_CONDITION.FETCH:
      return {
        ...state,
        paymentCondition: {
          ...state.paymentCondition,
          loading: true,
          error: false
        }
      };
    case ACTIONS.QUOTATIONS.PAYMENT_CONDITION.SUCCESS:
      return {
        ...state,
        paymentCondition: {
          ...state.paymentCondition,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.QUOTATIONS.PAYMENT_CONDITION.FAIL:
      return {
        ...state,
        paymentCondition: {
          ...state.paymentCondition,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.QUOTATIONS.UPDATE.FETCH:
      return {
        ...state,
        update: {
          ...state.update,
          loading: true,
          error: false
        }
      };
    case ACTIONS.QUOTATIONS.UPDATE.SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.QUOTATIONS.UPDATE.FAIL:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.QUOTATIONS.PRODUCER.FETCH:
      return {
        ...state,
        producer: {
          ...state.producer,
          loading: true,
          error: false
        }
      };
    case ACTIONS.QUOTATIONS.PRODUCER.SUCCESS:
      return {
        ...state,
        producer: {
          ...state.producer,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.QUOTATIONS.PRODUCER.FAIL:
      return {
        ...state,
        producer: {
          ...state.producer,
          loading: false,
          error: true,
          message: action.payload.error
        }
      };
    case ACTIONS.QUOTATIONS.ACCESORY.FETCH:
      return {
        ...state,
        accesory: {
          ...state.accesory,
          loading: true,
          error: false
        }
      };
    case ACTIONS.QUOTATIONS.ACCESORY.SUCCESS:
      return {
        ...state,
        accesory: {
          ...state.accesory,
          loading: false,
          error: false,
          data: action.payload
        }
      };
    case ACTIONS.QUOTATIONS.ACCESORY.FAIL:
      return {
        ...state,
        accesory: {
          ...state.accesory,
          loading: false,
          error: true,
          data: action.payload.error
        }
      };
    case ACTIONS.QUOTATIONS.CLEAN:
      return { ...initialState };

    default:
      return state;
  }
};
