import { call, put, takeLatest, all } from 'redux-saga/effects';
import Api from './Api';
import ApiMock from './ApiMock';
import { contactInfoSuccess, contactInfoFail } from './actions';
// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../ActionTypes';

const api = process.env.REACT_APP_USE_MOCKS === 'true' ? ApiMock() : Api();

function* sagasContactInfoFetch(action: actionType) {
  try {
    if (action.payload.contactId) {
      yield call(api.updateContactFetch, action.payload.contactId, action.payload.data);
    } else {
      let data = action.payload.data;
      if (!action?.payload?.data[0]) {
        data = [action?.payload?.data];
      }
      yield call(api.addContactFetch, data);
    }
    yield put(contactInfoSuccess());
  } catch (e) {
    yield put(contactInfoFail(e?.response?.data));
  }
}

// Listen for redux actions
function* contactInfoSaga() {
  yield all([takeLatest(ACTIONS.CONTACTS.CONTACT_INFO.FETCH, sagasContactInfoFetch)]);
}

export { contactInfoSaga };
