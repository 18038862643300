import React from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ErrorScreen } from '../../../../../assets/img/illustrations/error_screen.svg';
import { StyledButton } from '@wdynamo/common';
import { useHistory } from 'react-router-dom';
import { uris } from '../../../../../siteMap';

interface IErrorDetailProps {
  className: string;
  setDataForm(data: any): void;
  dataForm: any;
}

const CleanErrorDetailComponent: React.FC<IErrorDetailProps> = (props: IErrorDetailProps) => {
  const { className } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const handleReturnClick = () => {
    history.push(uris.services.uri);
  };

  return (
    <>
      <Grid container className={className} justifyContent='center'>
        <ErrorScreen />
        <Grid className='subtitle' item xs={12}>
          <Typography align='center'>{t('EMISSION.ERROR_REDIRECT_DESCRIPTION')}</Typography>
        </Grid>
        <StyledButton onClick={handleReturnClick}>Regresar al inicio</StyledButton>
      </Grid>
    </>
  );
};

export const ErrorDetailComponent = styled(({ ...props }) => <CleanErrorDetailComponent {...props} />)`
  .subtitle {
    margin: 2rem 0;
  }
`;
