import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import { ISourceParams } from './models';

const Api = () => {
  // eslint-disable-next-line no-unused-vars
  const getStepsFetch = (id: string): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/inputs/${id}`);
  // axios.get('https://run.mocky.io/v3/517d68a5-f414-4543-bc89-a1ebf911c64c');

  const getBankFetch = (): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/banks`);

  const getCardFetch = (): Promise<unknown> =>
    axios.get(
      `${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/credit_cards?Branch=5&InsuredType=001`
    );

  const getNationalityFetch = (): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/nationality`);

  const getProvinceFetch = (): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/departaments`);

  const getCityFetch = (params: any): Promise<unknown> =>
    axios.get(
      `${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/cities?departamentcode=${params.province}`
    );

  const getNeighborhoodFetch = (params: any): Promise<unknown> =>
    axios.get(
      `${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/neighborhoods?departamentcode=${params.province}&cityname=${params.city}`
    );

  const getIdTypeFetch = (): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/id_type`);

  const getMaritalStatusFetch = (): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/marital_status`);

  const getActivityFetch = (): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/activity`);

  const issueAddFetch = (params: any): Promise<unknown> =>
    axios.post(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/issue`, params);
  // axios.post('http://localhost:3005/addIssue', params);

  const getSourceList = (listParams: ISourceParams): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}${listParams.url}`, { params: listParams.params });

  const initializePersistenceFetch = (quotationExternalID: string): Promise<unknown> =>
    axios.post(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/issue/persistency/${quotationExternalID}`);

  const saveFirstStepFetch = (issueId: string, params: any): Promise<unknown> =>
    axios.patch(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/issue/persistency/${issueId}/persons`, params);

  const saveSecondStepFetch = (issueId: string, params: any): Promise<unknown> =>
    axios.patch(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/issue/persistency/${issueId}/inputs`, params);

  const getSecondInstallmentDateFetch = (): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_BASE_URL}/be-inputter/catalog/option/generic/secondExpirationDate`);

  // const saveThirdStepFetch = (issueId: string, params: any): Promise<unknown> =>
  //   axios.patch(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/issue/persistency/${issueId}/documents`, params);

  const saveFourthStepFetch = (issueId: string, params: any): Promise<unknown> =>
    axios.patch(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/issue/persistency/${issueId}/payments`, params);

  const getQuotationByIdFetch = (id: number): Promise<unknown> =>
    axios.get(`${process.env.REACT_APP_WDYNAMO_BASE_URL}/b2b/quotation/${id}`);

  return {
    getStepsFetch,
    getBankFetch,
    getCardFetch,
    getNationalityFetch,
    getProvinceFetch,
    getCityFetch,
    getNeighborhoodFetch,
    getIdTypeFetch,
    getMaritalStatusFetch,
    getActivityFetch,
    getSourceList,
    issueAddFetch,
    initializePersistenceFetch,
    saveFirstStepFetch,
    saveSecondStepFetch,
    // saveThirdStepFetch,
    saveFourthStepFetch,
    // se importa
    getQuotationByIdFetch,
    getSecondInstallmentDateFetch
  };
};

export default Api;
