import { ACTIONS } from '../../ActionTypes';

//get Steps
export const getStepsFetch = (text: string) => ({
  type: ACTIONS.ISSUE.STEPS.FETCH,
  payload: { params: text }
});

export const getStepsSuccess = (data: any) => ({
  type: ACTIONS.ISSUE.STEPS.SUCCESS,
  payload: { data: data }
});

export const getStepsFail = (error: object) => ({
  type: ACTIONS.ISSUE.STEPS.FAIL,
  payload: { error: error }
});

//get Bank
export const getBankFetch = () => ({
  type: ACTIONS.ISSUE.BANK.FETCH
});

export const getBankSuccess = (data: any) => ({
  type: ACTIONS.ISSUE.BANK.SUCCESS,
  payload: data
});

export const getBankFail = (error: object) => ({
  type: ACTIONS.ISSUE.BANK.FAIL,
  payload: { error: error }
});

//get Card
export const getCardFetch = () => ({
  type: ACTIONS.ISSUE.CARD.FETCH
});

export const getCardSuccess = (data: any) => ({
  type: ACTIONS.ISSUE.CARD.SUCCESS,
  payload: data
});

export const getCardFail = (error: object) => ({
  type: ACTIONS.ISSUE.CARD.FAIL,
  payload: { error: error }
});

//get Nationality
export const getNationalityFetch = () => ({
  type: ACTIONS.ISSUE.NATIONALITY.FETCH
});

export const getNationalitySuccess = (data: any) => ({
  type: ACTIONS.ISSUE.NATIONALITY.SUCCESS,
  payload: data
});

export const getNationalityFail = (error: object) => ({
  type: ACTIONS.ISSUE.NATIONALITY.FAIL,
  payload: { error: error }
});

//get Province
export const getProvinceFetch = () => ({
  type: ACTIONS.ISSUE.PROVINCE.FETCH
});

export const getProvinceSuccess = (data: any) => ({
  type: ACTIONS.ISSUE.PROVINCE.SUCCESS,
  payload: data
});

export const getProvinceFail = (error: object) => ({
  type: ACTIONS.ISSUE.PROVINCE.FAIL,
  payload: { error: error }
});

//get city
export const getCityFetch = (params: any) => ({
  type: ACTIONS.ISSUE.CITY.FETCH,
  payload: { params: params }
});

export const getCitySuccess = (data: any) => ({
  type: ACTIONS.ISSUE.CITY.SUCCESS,
  payload: data
});

export const getCityFail = (error: object) => ({
  type: ACTIONS.ISSUE.CITY.FAIL,
  payload: { error: error }
});
//get neighborhood
export const getNeighborhoodFetch = (params: any) => ({
  type: ACTIONS.ISSUE.NEIGHBORHOOD.FETCH,
  payload: { params }
});

export const getNeighborhoodSuccess = (data: any) => ({
  type: ACTIONS.ISSUE.NEIGHBORHOOD.SUCCESS,
  payload: data
});

export const getNeighborhoodFail = (error: object) => ({
  type: ACTIONS.ISSUE.NEIGHBORHOOD.FAIL,
  payload: { error: error }
});

//get IdType
export const getIdTypeFetch = () => ({
  type: ACTIONS.ISSUE.ID_TYPE.FETCH
});

export const getIdTypeSuccess = (data: any) => ({
  type: ACTIONS.ISSUE.ID_TYPE.SUCCESS,
  payload: data
});

export const getIdTypeFail = (error: object) => ({
  type: ACTIONS.ISSUE.ID_TYPE.FAIL,
  payload: { error: error }
});

//get MaritalStatus
export const getMaritalStatusFetch = () => ({
  type: ACTIONS.ISSUE.MARITAL_STATUS.FETCH
});

export const getMaritalStatusSuccess = (data: any) => ({
  type: ACTIONS.ISSUE.MARITAL_STATUS.SUCCESS,
  payload: data
});

export const getMaritalStatusFail = (error: object) => ({
  type: ACTIONS.ISSUE.MARITAL_STATUS.FAIL,
  payload: { error: error }
});

//get Activity
export const getActivityFetch = () => ({
  type: ACTIONS.ISSUE.ACTIVITY.FETCH
});

export const getActivitySuccess = (data: any) => ({
  type: ACTIONS.ISSUE.ACTIVITY.SUCCESS,
  payload: data
});

export const getActivityFail = (error: object) => ({
  type: ACTIONS.ISSUE.ACTIVITY.FAIL,
  payload: { error: error }
});

//issue

export const issueAddFetch = (params: any) => ({
  type: ACTIONS.ISSUE.CREATE.FETCH,
  payload: { params: params }
});

export const issueAddSuccess = (data: any) => ({
  type: ACTIONS.ISSUE.CREATE.SUCCESS,
  payload: data
});

export const issueAddFail = (error: object) => ({
  type: ACTIONS.ISSUE.CREATE.FAIL,
  payload: { error: error }
});

export const clearError = () => ({
  type: ACTIONS.ISSUE.CLEAR_ERROR
});

//cleanStore
export const cleanStore = () => ({
  type: ACTIONS.ISSUE.CLEAN
});

//get SourceList
export const getListSourceFetch = (params: any) => ({
  type: ACTIONS.ISSUE.LISTSOURCES.FETCH,
  payload: { params: params }
});

export const getListSourceSuccess = (data: any) => ({
  type: ACTIONS.ISSUE.LISTSOURCES.SUCCESS,
  payload: data
});

export const getListSourceFail = (error: object) => ({
  type: ACTIONS.ISSUE.LISTSOURCES.FAIL,
  payload: { error: error }
});

//initializePersistence
export const initializePersistenceFetch = (quotationExternalID: any) => ({
  type: ACTIONS.ISSUE.INITIALIZE_PERSISTENCE.FETCH,
  payload: { quotationExternalID: quotationExternalID }
});

export const initializePersistenceSuccess = (data: any) => ({
  type: ACTIONS.ISSUE.INITIALIZE_PERSISTENCE.SUCCESS,
  payload: data
});

export const initializePersistenceFail = (error: object) => ({
  type: ACTIONS.ISSUE.INITIALIZE_PERSISTENCE.FAIL,
  payload: { error: error }
});

//saveFirstStep
export const saveFirstStepFetch = (issueId: string, params: any) => ({
  type: ACTIONS.ISSUE.SAVE_FIRST_STEP.FETCH,
  payload: { issueId: issueId, params: params }
});

export const saveFirstStepSuccess = (data: any) => ({
  type: ACTIONS.ISSUE.SAVE_FIRST_STEP.SUCCESS,
  payload: data
});

export const saveFirstStepFail = (error: object) => ({
  type: ACTIONS.ISSUE.SAVE_FIRST_STEP.FAIL,
  payload: { error: error }
});

//saveSecondStep
export const saveSecondStepFetch = (issueId: string, params: any) => ({
  type: ACTIONS.ISSUE.SAVE_SECOND_STEP.FETCH,
  payload: { issueId: issueId, params: params }
});

export const saveSecondStepSuccess = (data: any) => ({
  type: ACTIONS.ISSUE.SAVE_SECOND_STEP.SUCCESS,
  payload: data
});

export const saveSecondStepFail = (error: object) => ({
  type: ACTIONS.ISSUE.SAVE_SECOND_STEP.FAIL,
  payload: { error: error }
});

//saveThirdStep
// export const saveThirdStepFetch = (issueId: string, params: any) => ({
//   type: ACTIONS.ISSUE.SAVE_THIRD_STEP.FETCH,
//   payload: { issueId: issueId, params: params }
// });

// export const saveThirdStepSuccess = (data: any) => ({
//   type: ACTIONS.ISSUE.SAVE_THIRD_STEP.SUCCESS,
//   payload: data
// });

// export const saveThirdStepFail = (error: object) => ({
//   type: ACTIONS.ISSUE.SAVE_THIRD_STEP.FAIL,
//   payload: { error: error }
// });

//saveFourthStep
export const saveFourthStepFetch = (issueId: string, params: any) => ({
  type: ACTIONS.ISSUE.SAVE_FOURTH_STEP.FETCH,
  payload: { issueId: issueId, params: params }
});

export const saveFourthStepSuccess = (data: any) => ({
  type: ACTIONS.ISSUE.SAVE_FOURTH_STEP.SUCCESS,
  payload: data
});

export const saveFourthStepFail = (error: object) => ({
  type: ACTIONS.ISSUE.SAVE_FOURTH_STEP.FAIL,
  payload: { error: error }
});

//getQuotation
export const getQuotationByIdFetch = (id: any) => ({
  type: ACTIONS.QUOTATION_BY_ID.FETCH,
  payload: { id: id }
});

export const getQuotationByIdSuccess = (data: any) => ({
  type: ACTIONS.QUOTATION_BY_ID.SUCCESS,
  payload: { data: data }
});

export const getQuotationByIdFail = (error: object) => ({
  type: ACTIONS.QUOTATION_BY_ID.FAIL,
  payload: { error: error }
});
export const getQuotationByIdFetchClean = () => ({
  type: ACTIONS.QUOTATION_BY_ID.CLEAN
});
export const getSecondInstallmentDateFetch = () => ({
  type: ACTIONS.ISSUE.SECOND_INSTALLMENT_DATE.FETCH
});

export const getSecondInstallmentDateSuccess = (data: any) => ({
  type: ACTIONS.ISSUE.SECOND_INSTALLMENT_DATE.SUCCESS,
  payload: { data: data }
});

export const getSecondInstallmentDateFail = (error: object) => ({
  type: ACTIONS.ISSUE.SECOND_INSTALLMENT_DATE.FAIL,
  payload: { error: error }
});
