import React from 'react';
import styled from 'styled-components';
import { Grid, TextField, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FormAddress } from './FormAddress';
import RucMask from '../common/RucMask';
import TextMask from '../common/TextMask';
// eslint-disable-next-line no-unused-vars
import { IQuotationInput } from '../../../../../Quotations/components/AutoQuotation/models';

interface IFormNPProp {
  className?: string;
  dataForm: any;
  handleOnChange(value: string, name: string): void;
  handleOnChangeRuc(e: any, path: string): void;
  statePath: string;
  activityList: any;
  provinceList: IQuotationInput[];
  cityList: IQuotationInput[];
  neighborhoodList: IQuotationInput[];
}

const CleanformLegalPerson: React.FC<IFormNPProp> = (props: IFormNPProp) => {
  const {
    dataForm,
    handleOnChange,
    handleOnChangeRuc,
    statePath,
    activityList,
    provinceList,
    cityList,
    neighborhoodList
  } = props;

  const invalidRuc = dataForm.id.error;
  const invalidRucMssg: string = 'Ruc inválido';

  return (
    <>
      <Grid item md={3}>
        <TextField
          name={`${statePath}.business_name`}
          value={dataForm.business_name.value}
          error={dataForm.business_name.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm.business_name.isRequired}
          label={'Razon social'}
          InputProps={{
            inputComponent: TextMask as any
          }}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item md={2}>
        <TextField
          name={`${statePath}.id`}
          value={dataForm.id.value}
          error={dataForm.id.error}
          onChange={(e) => handleOnChangeRuc(e, statePath)}
          fullWidth
          required={dataForm.id.isRequired}
          label={'R.U.C.'}
          InputProps={{
            inputComponent: RucMask as any
          }}
          InputLabelProps={{ shrink: true }}
          helperText={invalidRuc ? invalidRucMssg : ''}
        />
      </Grid>
      <Grid item md={2}>
        <TextField
          name={`${statePath}.province`}
          value={dataForm?.province?.value}
          error={dataForm?.province?.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm?.province?.isRequired}
          label={'Departamento'}
          select={true}
        >
          {provinceList.map((item: any, i: number) => (
            <MenuItem key={i} value={item.code}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={2}>
        <TextField
          name={`${statePath}.city`}
          value={dataForm?.city?.value}
          error={dataForm?.city?.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm?.city?.isRequired}
          label={'Ciudad'}
          disabled={!dataForm?.province?.value}
          select={true}
        >
          {cityList?.map((item: any, i: number) => (
            <MenuItem key={i} value={item.code}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item md={2}>
        <TextField
          name={`${statePath}.postal_code`}
          value={dataForm?.postal_code?.value}
          error={dataForm?.postal_code?.error}
          onChange={(event): void => handleOnChange(event.target.value, event.target.name)}
          fullWidth
          required={dataForm?.postal_code?.isRequired}
          disabled={!dataForm?.city?.value}
          label={'Barrio'}
          select={true}
        >
          {neighborhoodList?.map((item: any, i: number) => (
            <MenuItem key={i} value={item.code}>
              {item.description}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <FormAddress statePath={`${statePath}.address`} handleOnChange={handleOnChange} dataForm={dataForm.address} />
      {statePath !== 'creditor' && (
        <>
          <Grid item md={4}>
            <TextField
              name={`${statePath}.phone`}
              value={dataForm?.phone?.value}
              error={dataForm?.phone?.error}
              onChange={(event): void => handleOnChange(event?.target?.value, event?.target.name)}
              fullWidth
              required={dataForm.phone.isRequired}
              type='tel'
              label={'Teléfono empresarial'}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              name={`${statePath}.mail`}
              value={dataForm?.mail?.value}
              error={dataForm?.mail?.error}
              onChange={(event): void => handleOnChange(event.target.value, event.target?.name)}
              fullWidth
              required={dataForm.mail.isRequired}
              type='email'
              label={'Correo electrónico empresarial'}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item md={4}>
            <Autocomplete
              value={dataForm.activity?.value}
              options={activityList}
              noOptionsText={'Sin coincidencias'}
              getOptionLabel={(option: any) => option.description || ''}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  required={dataForm.activity.isRequired}
                  error={dataForm.activity.error}
                  label={'Actividad'}
                />
              )}
              onChange={(e: any, value: any) => handleOnChange(value, `${statePath}.activity`)}
              autoComplete
              autoHighlight
            />
          </Grid>
        </>
      )}
    </>
  );
};
export const FormLegalPerson = styled(({ ...props }) => <CleanformLegalPerson {...props} />)`
  .radio-label {
    font-size: 13px;
  }
`;
