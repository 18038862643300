// eslint-disable-next-line no-unused-vars
import { ACTIONS, actionType } from '../../../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IContactInfoStore } from './models';

const initialState: IContactInfoStore = {
  loading: false,
  error: false,
  contact: {},
  message: []
};

export const contactInfoReducer = (state = initialState, action: actionType) => {
  switch (action.type) {
    // Contat info
    case ACTIONS.CONTACTS.CONTACT_INFO.FETCH:
      return {
        ...state,
        loading: true,
        error: false,
        message: undefined
      };
    case ACTIONS.CONTACTS.CONTACT_INFO.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false
      };
    case ACTIONS.CONTACTS.CONTACT_INFO.FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload?.error?.data?.errors
      };

    default:
      return state;
  }
};
