import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Box, Container, Grid, Step, StepLabel } from '@material-ui/core';
import { Loading, Stepper, StyledButton, TextPoint } from '@wdynamo/common/lib/components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';

import { uris } from '../../../siteMap';
import { PersonDataStep } from './components/PersonDataStep';
import { CustomDataStep } from './components/CustomStep';
import { PaymentDataStep } from './components/PaymentStep';
// import { IdInfoStep } from './components/IdInfoStep';
import { ModalComponent, ColoredSymbolComponent } from '../../../components';
import { ReactComponent as SuccessIcon } from '../../../assets/img/icons_dynamo/Icon_success.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/img/icons_dynamo/icon_alert_fail.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/img/icons_dynamo/icon_download_circle.svg';
import { PrincipalState } from './principalState';
import { StepsRequest } from './stepsRequest';
import { useHistory } from 'react-router-dom';
import Api from '../../QuoteManagement/Api';

/* eslint-disable no-unused-vars */
import { IInput, ILocation, ISourceParams } from './models';
import {
  IBillingModeParams,
  IPaymentConditionParams,
  IPaymentMethodParams
} from '../Quotations/components/AutoQuotation/models';
import { ErrorDetail } from './components/error';
/* eslint-enable no-unused-vars */
interface EmissionsComponentProps {
  className: string;
  stepsStore: any;
  getBillingModeFetch(params: IBillingModeParams): void;
  getPaymentMethodFetch(params: IPaymentMethodParams): void;
  getPaymentConditionFetch(params: IPaymentConditionParams): void;
  getBankFetch(): void;
  getCardFetch(): void;
  getNationalityFetch(): void;
  getProvinceFetch(): void;
  getCityFetch(params: any): void;
  getNeighborhoodFetch(params: any): void;
  getIdTypeFetch(): void;
  getMaritalStatusFetch(): void;
  getActivityFetch(): void;
  getStepsFetch(text: string): void;
  location: ILocation;
  issueAddFetch(params: any): void;
  cleanStore(): void;
  clearError(): void;
  getListSourceFetch(listParams: ISourceParams): void;
  initializePersistenceFetch(quotationExternalId: string): void;
  saveFirstStepFetch(issueID: string, params: any): void;
  saveSecondStepFetch(issueID: string, params: any): void;
  // saveThirdStepFetch(issueID: string, params: any): void;
  saveFourthStepFetch(issueID: string, params: any): void;
  getQuotationByIdFetch(id: any): void;
  getSecondInstallmentDateFetch(): void;
}

type IssueError = {
  message: string;
  redirect: boolean;
};

const CleanEmissionsComponent: React.FC<EmissionsComponentProps> = (props: EmissionsComponentProps) => {
  const {
    className,
    stepsStore,
    getBillingModeFetch,
    getPaymentMethodFetch,
    getPaymentConditionFetch,
    getBankFetch,
    getCardFetch,
    getNationalityFetch,
    getProvinceFetch,
    getCityFetch,
    getNeighborhoodFetch,
    getIdTypeFetch,
    getMaritalStatusFetch,
    getActivityFetch,
    getStepsFetch,
    location,
    issueAddFetch,
    getListSourceFetch,
    cleanStore,
    clearError,
    initializePersistenceFetch,
    saveFirstStepFetch,
    saveSecondStepFetch,
    // saveThirdStepFetch,
    // saveFourthStepFetch,
    getQuotationByIdFetch,
    getSecondInstallmentDateFetch
  } = props;
  const principalState = new PrincipalState(location.state?.quotation);
  const { t } = useTranslation();
  const history = useHistory();
  let formDataCounterErrors: number;
  const fixedSteps: string[] = [t('EMISSION.STEP_1.LABEL'), '', t('EMISSION.STEP_2.LABEL')];
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [dataForm, setDataForm] = useState<any>(principalState.initialState);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [downloadingPdf, setDownloadingPdf] = useState<boolean>(false);
  const [errorDetail, setErrorDetail] = useState<IssueError | null>(null);
  const [title, setTitle] = useState<string>('');
  const [riskHasPledgee, setRiskHasPledgee] = useState<boolean>(false);
  const [isContractorInsured, setIsContractorInsured] = useState<boolean>(true);
  const [servicesLoaded, setServicesLoaded] = useState<boolean>(false);
  const [steps, setSteps] = React.useState<string[]>(fixedSteps);
  const stepsRequest = new StepsRequest(dataForm, location);

  const FIXABLE_ERRORS = ['issue', 'saveFirstStep', 'saveSecondStep', 'saveFourthStep'];

  useEffect(() => {
    if (!location.state?.quotation) history.push(uris.contacts.uri);
    else {
      getStepsFetch('1');
      getBankFetch();
      getCardFetch();
      getNationalityFetch();
      getProvinceFetch();
      getIdTypeFetch();
      getMaritalStatusFetch();
      getActivityFetch();
      handleGetPaymentList();
      getSecondInstallmentDateFetch();
      getQuotationByIdFetch(location.state.quotation.id);
      setDataForm((previousState: any) => {
        const newPersonData = previousState.person_data;
        newPersonData.contractor.name.value = location.state?.quotation?.customer.name ?? '';
        newPersonData.contractor.last_name.value = location.state?.quotation?.customer.last_name ?? '';
        newPersonData.contractor.mail.value = location.state?.quotation?.customer.mail ?? '';
        newPersonData.contractor.phone.value = location.state?.quotation?.customer.phone ?? '';
        return {
          ...previousState,
          person_data: newPersonData
        };
      });
      if (location.state?.quotation?.issue) {
        setDataForm(principalState.handleIssueDataRecovery());
      }
    }
    return () => {
      cleanStore();
    };
  }, []);

  useEffect(() => {
    const { data } = stepsStore.steps;
    data?.label && updateCustomStepLabel(stepsStore.steps.data.label);
    data?.title && setTitle(stepsStore.steps.data.title);
    createCustomState(data);
  }, [stepsStore.steps.data]);

  useEffect(() => {
    if (stepsStore.issue.data.Errores) {
      if (stepsStore.issue.data.Errores.length > 0) {
        setErrorDetail({ message: '', redirect: false });
      }
      setOpenModal(true);
    }
  }, [stepsStore.issue.loading]);

  useEffect(() => {
    let auxLoader = true;
    let hasError = false;
    let message = '';
    let redirect = false;
    let timeout = false;
    Object.keys(stepsStore).forEach((service) => {
      if (stepsStore[service].error) {
        hasError = true;
        const errorList = stepsStore[service].message?.ErrorList;
        if (errorList && Array.isArray(errorList)) {
          if (message.length > 0) message += '\n';
          message += errorList.map((err) => err.description).join('\n');
        }
        redirect = redirect || !FIXABLE_ERRORS.includes(service);
        timeout = timeout || stepsStore[service].timeout;
        setServicesLoaded(auxLoader);
        return;
      }
      if (stepsStore[service].loading) {
        auxLoader = false;
        if (stepsStore.nationality.data.length && currentStep === 1) auxLoader = true;
      }
    });
    if (hasError) {
      if (timeout) {
        setCurrentStep(-1);
      } else {
        setErrorDetail({ message, redirect });
        setOpenModal(true);
      }
    }
    setServicesLoaded(auxLoader);
  }, [stepsStore]);

  useEffect(() => {
    if (stepsStore.initializePersistence.data && !stepsStore.initializePersistence.loading)
      saveFirstStepFetch(String(location.state.quotation.quotationExternalId), {
        product_id: location.state.quotation.product.id,
        request: location.state.quotation.quotationExternalId,
        ...getRequestStep1()
      });
  }, [stepsStore.initializePersistence.loading]);

  const handleGetPaymentList = () => {
    const quotationInfo = location.state?.quotation;
    const productId = String(quotationInfo.product.id);
    const producer = quotationInfo.producer;
    // const policyType = quotationInfo.product.sub_branch;
    const policyType = quotationInfo.product.sub_product_list[0].subBranchId;
    const commercialPlan = quotationInfo.commercialPlan;
    const billingMode = quotationInfo.billingMethod;
    const paymentMethod = quotationInfo.paymentMethod;
    const currency = '';
    const company = '1';
    const branch = quotationInfo.product.branch;
    const date = moment(quotationInfo.createdAt).format('YYYYMMDD');
    getBillingModeFetch({
      productId: productId,
      producer: producer,
      commercialPlan: commercialPlan,
      currency: currency
    });
    getPaymentMethodFetch({
      productId: productId,
      commercialPlan: commercialPlan,
      billingMode: billingMode,
      currency: currency
    });
    getPaymentConditionFetch({
      company: company,
      branch: branch,
      date: date,
      producer: producer,
      commercialPlan: commercialPlan,
      billingMode: billingMode,
      currency: currency,
      policyType: policyType,
      paymentMethod: paymentMethod
    });
  };

  const updateCustomStepLabel = (label: string) => {
    const listLabels = [...steps];
    listLabels[1] = label;
    setSteps(listLabels);
  };

  const createCustomState = (data: any) => {
    let customStateUpdate = {};
    data?.input_groups &&
      data?.input_groups[0].inputs?.length &&
      data.input_groups[0].inputs.forEach((input: IInput) => {
        const inputValue = input.default_value ? input.default_value : '';
        customStateUpdate = {
          ...customStateUpdate,
          [input.name]: {
            value:
              input.type === 'FILE'
                ? { file_name: '', string_base_64: '' }
                : { code: inputValue, description: inputValue },
            error: false,
            isRequired: input.is_required
          }
        };
      });
    setDataForm({ ...dataForm, custom_data: customStateUpdate });
  };

  const getRequestStep1 = () => stepsRequest.step1(riskHasPledgee, isContractorInsured);

  const getRequestStep2 = () => stepsRequest.step2(stepsStore);

  const getIndividualRequestStep2 = () => stepsRequest.individualStep2();

  // const getRequestStep3 = () => stepsRequest.step3();

  // const getIndividualRequestStep3 = () => stepsRequest.individualStep3();

  const getRequestStep4 = () => stepsRequest.step4();

  // eslint-disable-next-line no-unused-vars
  const getIndividualRequestStep4 = () => stepsRequest.individualStep4();

  const getRequest = () => {
    return {
      product_id: location.state.quotation.product.id,
      request: location.state.quotation.quotationExternalId,
      branch: location.state.quotation.product.branch,
      nationalizationYear: location.state.quotation.inputs['year' as any]?.description ?? '',
      plan_code: 'COV',
      ...getRequestStep1(),
      ...getRequestStep2(),
      // ...getRequestStep3(),
      ...getRequestStep4()
    };
  };

  const defaultRedirect = <Redirect to={uris.services.uri} />;

  const handleDownloadPdf = (id: any) => {
    setDownloadingPdf(true);
    new Api().getDownloadPDF(id).then((response: any) => {
      if (response) {
        const url = window.URL.createObjectURL(response?.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'solicitud-de-emision.pdf');
        document.body.appendChild(link);
        link.click();
      }
      setDownloadingPdf(false);
    });
  };

  const handleRenderModal = () => {
    const color = errorDetail ? 'var(--color-red)' : 'var(--color-green)';
    const hasError = errorDetail !== null;
    return (
      <ModalComponent
        open={openModal}
        icon={hasError ? <ErrorIcon width={100} height={80} /> : <SuccessIcon width={100} height={80} />}
        title={
          hasError ? (
            t('EMISSION.ERROR_MODAL_TITLE')
          ) : (
            <>
              <ColoredSymbolComponent value={'¡'} color={color} />
              {t('EMISSION.FIRST_MODAL_TITLE')}
              {stepsStore.issue.data.Poliza !== '0' && (
                <>
                  {t('EMISSION.SECOND_MODAL_TITLE')}
                  <ColoredSymbolComponent value={stepsStore.issue.data.Poliza} color={color} />
                </>
              )}
              <ColoredSymbolComponent value={'!'} color={color} />
            </>
          )
        }
        description={
          hasError
            ? errorDetail?.redirect
              ? t('EMISSION.ERROR_REDIRECT_DESCRIPTION')
              : t('EMISSION.ERROR_MODAL_DESCRIPTION')
            : t('EMISSION.FIRST_MODAL_DESCRIPTION')
        }
        details={errorDetail?.message}
        loadingButtons={downloadingPdf}
        iconColor={hasError ? '' : color}
        twoButtons={!hasError}
        buttonColor={hasError ? color : 'var(--color-main)'}
        buttonText={hasError ? t('COMMONS.BUTTONS.ACCEPT') : t('EMISSION.DOWNLOAD_REQUEST')}
        buttonLoadingText={t('COMMONS.MODALS.DOWNLOADING')}
        buttonIcon={!hasError && (downloadingPdf ? null : <DownloadIcon />)}
        onClickButton={hasError ? handleCloseModal : () => handleDownloadPdf(location.state.quotation.id)}
        secondButtonColor={color}
        secondButtonText={t('COMMONS.BUTTONS.CONTINUE')}
        onClickSecondButton={handleCloseModal}
        handleCloseCrossModal={handleCloseModal}
      />
    );
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    if (!errorDetail || errorDetail?.redirect) {
      history.push(uris.services.uri);
    } else {
      setTimeout(() => {
        setErrorDetail(null);
        clearError();
      }, 500);
    }
  };

  console.log('stepsStore en view', stepsStore);
  console.log('dataForm', dataForm);

  const getStepContent = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <PersonDataStep
            setDataForm={setDataForm}
            dataForm={dataForm}
            personDataForm={dataForm.person_data}
            riskHasPledgee={riskHasPledgee}
            setRiskHasPledgee={setRiskHasPledgee}
            isContractorInsured={isContractorInsured}
            setIsContractorInsured={setIsContractorInsured}
            nationalityList={stepsStore.nationality.data}
            provinceList={stepsStore.province.data}
            contractorNeighborhoodList={
              dataForm.person_data.contractor.type.value === 'legal'
                ? stepsStore.contractor_neighborhood_legal?.data
                : stepsStore?.contractor_neighborhood?.data
            }
            contractorCityList={
              dataForm.person_data.contractor.type.value === 'legal'
                ? stepsStore?.contractor_city_legal?.data
                : stepsStore?.contractor_city?.data
            }
            creditorCityList={stepsStore?.creditor_city?.data}
            creditorNeighborhoodList={stepsStore?.creditor_neighborhood?.data}
            insuredNeighborhoodList={stepsStore?.insured_neighborhood?.data}
            insuredCityList={stepsStore?.insured_city?.data}
            idTypeList={stepsStore.idType.data}
            maritalStatusList={stepsStore.maritalStatus.data}
            activityList={stepsStore.activity.data}
            getCityFetch={getCityFetch}
            getNeighborhoodFetch={getNeighborhoodFetch}
          />
        );
      case 1:
        return (
          <CustomDataStep
            setDataForm={setDataForm}
            dataForm={dataForm}
            customDataForm={dataForm.custom_data}
            inputter={stepsStore.steps.data}
            quotationInputs={location?.state?.quotation.inputs}
            getListSourceFetch={getListSourceFetch}
            listSources={stepsStore.listSources}
          />
        );
      // case 2:
      //   return <IdInfoStep setDataForm={setDataForm} dataForm={dataForm} />;
      case 2 || 3:
        return (
          <PaymentDataStep
            setDataForm={setDataForm}
            dataForm={dataForm}
            quotationData={location.state.quotation}
            billingMethodList={stepsStore.billingMode.data}
            paymentMethodList={stepsStore.paymentMethod.data}
            paymentConditionList={stepsStore.paymentCondition.data}
            secondInstallmentDate={stepsStore?.secondInstallmentDate?.data?.data}
            bankList={stepsStore.bank.data}
            cardList={stepsStore.card.data}
          />
        );
      default:
        return defaultRedirect;
    }
  };

  const handleValidateFields = (fieldsObject: any, skipFields?: string[]) => {
    const prevDataForm = { ...dataForm };
    const invalidRuc: boolean = dataForm.person_data.contractor.id.error === true;
    Object.keys(fieldsObject).forEach((field) => {
      if (!skipFields || !skipFields.includes(field)) {
        if (
          (fieldsObject[field].value === '' ||
            fieldsObject[field].value?.description === '' ||
            fieldsObject[field].value?.code === '') &&
          fieldsObject[field].isRequired
        ) {
          fieldsObject[field].error = true;
          setDataForm(prevDataForm);
          formDataCounterErrors++;
        } else if (invalidRuc) {
          fieldsObject.id.error = true;
          setDataForm(prevDataForm);
        } else {
          fieldsObject[field].error = false;
          setDataForm(prevDataForm);
        }
      }
    });
  };

  const handleValidatePersonData = (fieldsObject: any) => {
    let skipFields: any = [];
    const commonFields = ['address', 'holder', 'spouse', 'pep'];
    Object.keys(fieldsObject).forEach((typePerson) => {
      const creditorConditions = typePerson === 'creditor' && riskHasPledgee;
      const contractorConditions = typePerson === 'contractor';
      const insuredConditions = typePerson === 'insured' && !isContractorInsured;
      if (creditorConditions || contractorConditions || insuredConditions) {
        const aux = fieldsObject[typePerson];
        let skipFieldsContractor = ['business_name'];
        if (typePerson === 'contractor' && aux.type?.value === 'legal')
          skipFieldsContractor = [
            'birth_place',
            // 'city',
            'date_of_birth',
            'id_type',
            'last_name',
            'marital_status',
            'name',
            'nationality',
            // 'postal_code',
            // 'province',
            'sex'
          ];
        skipFields = commonFields.concat(skipFieldsContractor);
        const skipFieldsCreditor = ['activity', 'mail', 'phone'];
        if (typePerson === 'creditor') skipFields = commonFields.concat(skipFieldsCreditor);
        commonFields.forEach((commonField: string) => {
          const addressConditions = commonField === 'address';
          const holderConditions = commonField === 'holder' && !aux.is_holder.value;
          const spouseConditions = commonField === 'spouse' && aux.marital_status?.value === '1';
          const pepConditions = commonField === 'pep' && aux.is_pep?.value;
          if (addressConditions || holderConditions || spouseConditions || pepConditions)
            handleValidateFields(aux[commonField]);
        });
        handleValidateFields(fieldsObject[typePerson], skipFields);
      }
    });
  };

  const handleValidateDataFields = (stepName: string) => {
    const fieldsObject = dataForm[stepName];
    formDataCounterErrors = 0;
    // eslint-disable-next-line no-prototype-builtins
    if (fieldsObject.hasOwnProperty('contractor')) handleValidatePersonData(fieldsObject);
    else handleValidateFields(fieldsObject);
    if (!formDataCounterErrors) {
      let issueId: string;
      switch (currentStep) {
        case 0:
          if (location.state.quotation.issue) {
            saveFirstStepFetch(location.state.quotation.issue.request, {
              product_id: location.state.quotation.product.id,
              request: location.state.quotation.quotationExternalId,
              ...getRequestStep1()
            });
          } else {
            initializePersistenceFetch(String(location.state.quotation.quotationExternalId));
            setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
          }
          break;
        case 1:
          issueId = stepsStore.saveFirstStep.data.toString();
          saveSecondStepFetch(issueId, { ...getIndividualRequestStep2() });
          setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
          break;
        // case 2:
        //   issueId = stepsStore.saveSecondStep.data.toString();
        //   saveThirdStepFetch(issueId, { ...getIndividualRequestStep3() });
        //   setCurrentStep((prevCurrentStep) => prevCurrentStep + 1);
        //   break;
        case 2:
          //issueId = stepsStore.saveSecondStep.data.toString();
          //saveFourthStepFetch(issueId, { ...getIndividualRequestStep4() });
          issueAddFetch(getRequest());
          break;
      }
    }
  };

  const handleNextStep = () => {
    switch (currentStep) {
      case 0:
        handleValidateDataFields('person_data');
        break;
      case 1:
        handleValidateDataFields('custom_data');
        break;
      // case 2:
      //   handleValidateDataFields('id_info_data');
      //   break;
      case 2:
        handleValidateDataFields('payment_data');
        break;
    }
  };

  const handlePrevStep = () => {
    if (currentStep === 0 || currentStep === 5) history.push(uris.contacts.uri);
    setCurrentStep((prevCurrentStep) => prevCurrentStep - 1);
  };

  const isFirstStep = () => {
    return currentStep === 0;
  };

  return (
    <Container className={className}>
      {currentStep === -1 ? (
        <ErrorDetail className='error-detail' />
      ) : (
        <>
          <Grid item>
            <h1>
              {`Emisión: ${title}`}
              <TextPoint />
            </h1>
          </Grid>
          {currentStep < 4 && (
            <>
              <Box className='labels-root'>
                <Grid container>
                  {steps.map((label, i) => (
                    <Box key={label} className={`${currentStep === i && 'label-active'} label-item`}>
                      <Grid item>{label}</Grid>
                    </Box>
                  ))}
                </Grid>
              </Box>
              <Stepper activeStep={currentStep} alternativeLabel steps={steps}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </>
          )}
          {servicesLoaded ? (
            <>
              {handleRenderModal()}
              {getStepContent(currentStep)}
              {currentStep >= 0 && (
                <Box paddingTop={10}>
                  <Grid container justifyContent='space-between'>
                    <Grid item>
                      <StyledButton onClick={() => handlePrevStep()} className='secondary '>
                        {isFirstStep() ? t('COMMONS.BUTTONS.CANCEL') : t('COMMONS.BUTTONS.GOBACK')}
                      </StyledButton>
                    </Grid>
                    <Grid item>
                      {currentStep < 5 && (
                        <StyledButton onClick={() => handleNextStep()} className='default next-button'>
                          {t('COMMONS.BUTTONS.CONTINUE')}
                        </StyledButton>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          ) : (
            <Loading />
          )}
        </>
      )}
    </Container>
  );
};

export const EmissionsComponent = styled(({ ...props }) => <CleanEmissionsComponent {...props} />)`
  .labels-root {
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
  }
  .label-item {
    padding-left: 8px;
    padding-right: 8px;
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .label-active {
    font-weight: bolder;
  }
  .next-button {
    padding: 10px 120px;
    font-size: 20px;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .error-detail {
    margin: 140px 0;
  }
`;
