import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { uris } from '../../../siteMap';
import { Analytics } from '@wdynamo/common/lib/services';
import { StyledButton } from '@wdynamo/common/lib/components';
import { Grid, CardMedia } from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';
// eslint-disable-next-line no-unused-vars
import { IItem } from './models';

interface ServicesListComponentProps {
  servicesStore: any;
  className?: string;
  getServicesFetch(): void;
}

const CleanServicesListComponent: React.FC<ServicesListComponentProps> = (props: ServicesListComponentProps) => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const history = useHistory();
  const { servicesStore } = props;
  const [productList, setProductList] = useState<any>([]);
  const [searched, setSearched] = useState<string>('');

  useEffect(() => {
    Analytics().pageview('display-services');
  }, []);

  useEffect(() => {
    if (keycloak?.authenticated)
      setTimeout(() => {
        props.getServicesFetch();
      });
  }, [keycloak?.authenticated]);

  useEffect(() => {
    setProductList(servicesStore?.data);
  }, [servicesStore]);

  const requestSearch = (searchedVal: string) => {
    const filteredRows = servicesStore?.data.filter((item: IItem) => {
      return item.name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setProductList(filteredRows);
  };

  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };

  return (
    <div className={`${props.className} services`}>
      <Grid container className='header'>
        <Grid item>
          <h1 className='main-title'>{t('SERVICES.TITLE')}</h1>
        </Grid>
        {/* <Grid item xs={12} md={6} className='add-service'>
          <Grid className='icon-label' item>
            <IconLabel
              label={t('SERVICES.BUTTON_ADD_SERVICE')}
              onClick={() => {
                history.push(uris?.newService.uri);
              }}
            />
          </Grid>
        </Grid> */}
        <Grid item className='search-bar'>
          <SearchBar
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
            placeholder={t('SERVICES.PLACEHOLDER_SEARCHER')}
            style={{
              border: '1px solid #B1ACAC',
              boxSizing: 'border-box',
              borderRadius: '4px',
              boxShadow: 'none',
              height: '2.5rem'
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} justify='center'>
        {productList?.map((service: any, key: string) => (
          <Grid container direction='column' key={key} item xs={12} sm={6} md={4} lg={3}>
            <Grid item className='card-header'>
              <div className='title'>
                <h3 className='card-title'>{service.name}</h3>
              </div>
            </Grid>
            <Grid item className='card-content'>
              <CardMedia image={service.thumbnail} className='logo' />
              <div className='sell'>
                {/* TODO: NO DEJAR MAGIC NUMBER */}
                <StyledButton
                  onClick={() => {
                    (service.id === 1 && history.push(uris.quotationsAuto.uri, { service: service })) ||
                      (service.id === 2 && history.push(uris.quotationsHome.uri, { service: service })) ||
                      (service.id === 3 && history.push(uris.quotationsAp.uri, { service: service }));
                  }}
                >
                  {t('SERVICES.LIST')}
                </StyledButton>
              </div>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export const ServicesListComponent = styled(({ ...props }) => <CleanServicesListComponent {...props} />)`
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0 5rem 0;
  }
  .main-title {
    font-size: 1.75rem;
  }
  .title {
    border-bottom: #666;
  }
  .search-bar {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
  .card-title {
    color: #ffffff;
    text-align: center;
    font-size: 1.25rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
  .card-content {
    min-height: 20em;
    background-color: var(--color-white);
    border-radius: 0 0 16px 16px;
    position: relative;
  }
  .MuiCardContent-root {
    padding-bottom: 0.2em;
    padding-top: 0;
  }
  .sell {
    position: relative;
    top: -60px;
    text-align: center;
  }
  .add-service {
    text-align: end;
    margin-top: 1.675rem;
  }
  .logo {
    position: relative;
    height: 100%;
    object-fit: cover;
    margin: 0;
    padding: 0;
    border-radius: 0 0 16px 16px;
  }
  .card-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.7em 2em;
    color: var(--color-white);
    background-color: var(--color-main);
    border-radius: 16px 16px 0 0;
    height: 5rem;
  }
`;
