import { ACTIONS } from '../../../ActionTypes';
// eslint-disable-next-line no-unused-vars
import { IContact } from './models';

// Contact Info
export const contactInfoFetch = (contactInfoForm: IContact, contactId?: string) => ({
  type: ACTIONS.CONTACTS.CONTACT_INFO.FETCH,
  payload: { data: contactInfoForm, contactId: contactId }
});

export const contactInfoSuccess = () => ({ type: ACTIONS.CONTACTS.CONTACT_INFO.SUCCESS });

export const contactInfoFail = (error: object) => ({ type: ACTIONS.CONTACTS.CONTACT_INFO.FAIL, payload: { error } });
