import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';

import { DatePicker } from '@wdynamo/common';
// eslint-disable-next-line no-unused-vars
import { IQuotationInput } from '../../models';

interface IPaymentDataProps {
  className: string;
  setDataForm(data: any): void;
  dataForm: any;
  quotationData: any;
  billingMethodList: IQuotationInput[];
  paymentMethodList: IQuotationInput[];
  paymentConditionList: IQuotationInput[];
  cardList: IQuotationInput[];
  bankList: IQuotationInput[];
  secondInstallmentDate: any;
}

const EXPIRATION_DATE_FORMAT = 'DD/MM/YY';
const CREDIT_EXPIRATION_DATE_FORMAT = 'MM/YY';
const CREDIT_CARD_LENGTH = 19;
const CREDIT_CARD_AMEX_LENGTH = 17;
const DEBIT_ACCOUNT_LENGTH = 11;

const creditCardNumberMask = React.forwardRef<HTMLElement, any>(function creditCardNumberMask(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='0000 0000 0000 0000'
      maxLength={CREDIT_CARD_LENGTH}
      inputRef={ref}
      // eslint-disable-next-line react/prop-types
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      unmask
    />
  );
});

const creditCardAmexNumberMask = React.forwardRef<HTMLElement, any>(function creditCardAmexNumberMask(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='0000 000000 00000'
      maxLength={CREDIT_CARD_AMEX_LENGTH}
      inputRef={ref}
      // eslint-disable-next-line react/prop-types
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      unmask
    />
  );
});

const accountNumberMask = React.forwardRef<HTMLElement, any>(function accountNumberMask(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask='00000000000'
      inputRef={ref}
      // eslint-disable-next-line react/prop-types
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      unmask
    />
  );
});

const formatDate = (input: any) => {
  const datePart = input.match(/\d+/g),
    year = datePart[0].substring(2), // get only two digits
    month = datePart[1],
    day = datePart[2];

  return day + '/' + month + '/' + year;
};

const CleanPaymentDataComponent: React.FC<IPaymentDataProps> = (props: IPaymentDataProps) => {
  const {
    className,
    setDataForm,
    dataForm,
    quotationData,
    billingMethodList,
    paymentMethodList,
    paymentConditionList,
    cardList,
    bankList,
    secondInstallmentDate
  } = props;
  const { t } = useTranslation();

  const [pickerMaxDate, setPickerMaxDate] = useState<moment.Moment>(moment());

  useEffect(() => {
    const billingModeEvent = { target: { name: 'amount_of_payments', value: quotationData.billingMethod } };
    const paymentMethodEvent = { target: { name: 'payment_method', value: quotationData.paymentMethod } };
    const fixedFields = [billingModeEvent, paymentMethodEvent];
    fixedFields.forEach((field) => handleOnChange(field));
    dataForm.payment_data.second_payment_expiration.value = formatDate(secondInstallmentDate);
    setPickerMaxDate(moment().add(45, 'days'));
    /*const today = moment(new Date()).add(1, 'M').format('DD/MM/YYYY');

    dataForm.payment_data.second_payment_expiration.value = today;*/
  }, []);

  const handleOnChange = (event: any) => {
    const field = event.target.name;
    const value = event.target.value;
    const prevState = { ...dataForm };
    prevState.payment_data[field].value = value;
    setDataForm(prevState);
  };

  const handleOnBlur = (event: any) => {
    const field = event.target.name;
    const value = event.target.value;
    const prevState = { ...dataForm };
    let hasError = false;
    if (field === 'card_number') {
      const targetLength = dataForm.payment_data.card.value === 'AMEX' ? CREDIT_CARD_AMEX_LENGTH : CREDIT_CARD_LENGTH;
      hasError = value.length !== targetLength;
    }
    if (field === 'account_number') {
      hasError = value.length !== DEBIT_ACCOUNT_LENGTH;
    }
    prevState.payment_data[field].error = hasError;
    setDataForm(prevState);
  };

  const accountTypeList = [
    {
      code: 'CA',
      description: t('EMISSION.STEP_2.ACCOUNT_TYPE_1')
    },
    {
      code: 'CC',
      description: t('EMISSION.STEP_2.ACCOUNT_TYPE_2')
    }
  ];

  const renderCreditCardFields = () => {
    dataForm.payment_data.account_number.isRequired = false;
    dataForm.payment_data.bank.isRequired = false;
    dataForm.payment_data.bank_ob_account_type.isRequired = false;
    return (
      <Grid container spacing={1} className={className}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name='billing_mode'
            defaultValue={quotationData.billingMethod}
            onChange={handleOnChange}
            label={t('EMISSION.STEP_2.BILLING_MODE')}
            select
            disabled
            fullWidth
          >
            {billingMethodList.map((card: any) => (
              <MenuItem key={card.code} value={card.code}>
                {card.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name='payment_method'
            defaultValue={quotationData.paymentMethod}
            onChange={handleOnChange}
            label={t('EMISSION.STEP_2.PAYMENT_METHOD')}
            select
            disabled
            fullWidth
          >
            {paymentMethodList.map((card: any) => (
              <MenuItem key={card.code} value={card.code}>
                {card.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name='payment_condition'
            defaultValue={quotationData.paymentCondition}
            onChange={handleOnChange}
            label={t('EMISSION.STEP_2.PAYMENT_CONDITION')}
            select
            disabled
            fullWidth
          >
            {paymentConditionList.map((card: any) => (
              <MenuItem key={card.code} value={card.code}>
                {card.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DatePicker
            error={dataForm.payment_data.second_payment_expiration.error}
            format={EXPIRATION_DATE_FORMAT}
            value={dataForm.payment_data.second_payment_expiration.value}
            minDate={moment()}
            maxDate={pickerMaxDate}
            onChange={(value) => handleOnChange({ target: { name: 'second_payment_expiration', value } })}
            label={t('EMISSION.STEP_2.SECOND_PAYMENT_EXPIRATION')}
            required={dataForm.payment_data.second_payment_expiration.isRequired}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name='bank'
            error={dataForm.payment_data.bank.error}
            value={dataForm.payment_data.bank.value}
            onChange={handleOnChange}
            label={t('EMISSION.STEP_2.BANK')}
            select
            fullWidth
            disabled
          >
            {bankList.map((bank: any) => (
              <MenuItem key={bank.code} value={bank.code}>
                {bank.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name='card'
            error={dataForm.payment_data.card.error}
            value={dataForm.payment_data.card.value}
            onChange={handleOnChange}
            label={t('EMISSION.STEP_2.CARD')}
            required={dataForm.payment_data.card.isRequired}
            select
            fullWidth
          >
            {cardList.map((card: any) => (
              <MenuItem key={card.code} value={card.code}>
                {card.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name='card_number'
            error={dataForm.payment_data.card_number.error}
            value={dataForm.payment_data.card_number.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            label={t('EMISSION.STEP_2.CARD_NUMBER')}
            InputProps={{
              inputComponent:
                dataForm.payment_data.card.value === 'AMEX' ? creditCardAmexNumberMask : creditCardNumberMask
            }}
            InputLabelProps={{
              shrink: true
            }}
            required={dataForm.payment_data.card_number.isRequired}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePicker
            error={dataForm.payment_data.due_date.error}
            openTo='year'
            views={['year', 'month']}
            format={CREDIT_EXPIRATION_DATE_FORMAT}
            // minDate={moment().add(1, 'months').startOf('months')}
            minDate={moment().startOf('month')}
            minDateMessage={''}
            value={dataForm.payment_data.due_date.value}
            onChange={(value) => handleOnChange({ target: { name: 'due_date', value } })}
            label={t('EMISSION.STEP_2.DUE_DATE')}
            required={dataForm.payment_data.due_date.isRequired}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  };

  const renderDebitCardFields = () => {
    dataForm.payment_data.due_date.isRequired = false;
    dataForm.payment_data.card.isRequired = false;
    dataForm.payment_data.bank.value = '105';
    dataForm.payment_data.card_number.isRequired = false;

    return (
      <Grid container spacing={1} className={className}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name='billing_mode'
            defaultValue={quotationData.billingMethod}
            onChange={handleOnChange}
            label={t('EMISSION.STEP_2.BILLING_MODE')}
            select
            disabled
            fullWidth
          >
            {billingMethodList.map((card: any) => (
              <MenuItem key={card.code} value={card.code}>
                {card.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name='payment_method'
            defaultValue={quotationData.paymentMethod}
            onChange={handleOnChange}
            label={t('EMISSION.STEP_2.PAYMENT_METHOD')}
            select
            disabled
            fullWidth
          >
            {paymentMethodList.map((card: any) => (
              <MenuItem key={card.code} value={card.code}>
                {card.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name='payment_condition'
            defaultValue={quotationData.paymentCondition}
            onChange={handleOnChange}
            label={t('EMISSION.STEP_2.PAYMENT_CONDITION')}
            select
            disabled
            fullWidth
          >
            {paymentConditionList.map((card: any) => (
              <MenuItem key={card.code} value={card.code}>
                {card.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DatePicker
            error={dataForm.payment_data.second_payment_expiration.error}
            format={EXPIRATION_DATE_FORMAT}
            value={dataForm.payment_data.second_payment_expiration.value}
            minDate={moment()}
            maxDate={pickerMaxDate}
            onChange={(value) => handleOnChange({ target: { name: 'second_payment_expiration', value } })}
            label={t('EMISSION.STEP_2.SECOND_PAYMENT_EXPIRATION')}
            required={dataForm.payment_data.second_payment_expiration.isRequired}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name='bank'
            error={dataForm.payment_data.bank.error}
            value={dataForm.payment_data.bank.value}
            onChange={handleOnChange}
            label={t('EMISSION.STEP_2.BANK')}
            required={dataForm.payment_data.bank.isRequired}
            select
            fullWidth
          >
            {bankList.map((bank: any) => (
              <MenuItem key={bank.code} value={bank.code}>
                {bank.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name='bank_ob_account_type'
            error={dataForm.payment_data.bank_ob_account_type.error}
            value={dataForm.payment_data.bank_ob_account_type.value}
            onChange={handleOnChange}
            label={t('EMISSION.STEP_2.ACCOUNT_TYPE')}
            required={dataForm.payment_data.bank_ob_account_type.isRequired}
            select
            fullWidth
          >
            {accountTypeList.map((type: any) => (
              <MenuItem key={type.code} value={type.code}>
                {type.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name='account_number'
            error={dataForm.payment_data.account_number.error}
            value={dataForm.payment_data.account_number.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            label={t('EMISSION.STEP_2.ACCOUNT_NUMBER')}
            InputProps={{
              inputComponent: accountNumberMask as any
            }}
            InputLabelProps={{
              shrink: true
            }}
            required={dataForm.payment_data.account_number.isRequired}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  };

  const renderManualPaymentFields = () => {
    dataForm.payment_data.bank.isRequired = false;
    dataForm.payment_data.card.isRequired = false;
    dataForm.payment_data.card_number.isRequired = false;
    dataForm.payment_data.bank_ob_account_type.isRequired = false;
    dataForm.payment_data.account_number.isRequired = false;
    dataForm.payment_data.due_date.isRequired = false;

    return (
      <Grid container spacing={1} className={className}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name='billing_mode'
            defaultValue={quotationData.billingMethod}
            onChange={handleOnChange}
            label={t('EMISSION.STEP_2.BILLING_MODE')}
            select
            disabled
            fullWidth
          >
            {billingMethodList.map((card: any) => (
              <MenuItem key={card.code} value={card.code}>
                {card.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name='payment_method'
            defaultValue={quotationData.paymentMethod}
            onChange={handleOnChange}
            label={t('EMISSION.STEP_2.PAYMENT_METHOD')}
            select
            disabled
            fullWidth
          >
            {paymentMethodList.map((card: any) => (
              <MenuItem key={card.code} value={card.code}>
                {card.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name='payment_condition'
            defaultValue={quotationData.paymentCondition}
            onChange={handleOnChange}
            label={t('EMISSION.STEP_2.PAYMENT_CONDITION')}
            select
            disabled
            fullWidth
          >
            {paymentConditionList.map((card: any) => (
              <MenuItem key={card.code} value={card.code}>
                {card.description}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DatePicker
            error={dataForm.payment_data.second_payment_expiration.error}
            format={EXPIRATION_DATE_FORMAT}
            value={dataForm.payment_data.second_payment_expiration.value}
            minDate={moment()}
            maxDate={pickerMaxDate}
            onChange={(value) => handleOnChange({ target: { name: 'second_payment_expiration', value } })}
            label={t('EMISSION.STEP_2.SECOND_PAYMENT_EXPIRATION')}
            required={dataForm.payment_data.second_payment_expiration.isRequired}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  };

  const renderForm = () => {
    if (quotationData.paymentMethod === '0') return renderManualPaymentFields();
    if (quotationData.paymentMethod === '2') return renderDebitCardFields();
    if (quotationData.paymentMethod === '3') return renderCreditCardFields();
  };

  return <>{renderForm()}</>;
};

export const PaymentDataComponent = styled(({ ...props }) => <CleanPaymentDataComponent {...props} />)``;
